/* eslint-disable react/forbid-prop-types */
import React from "react";
import { graphql } from "gatsby";
import tw from "twin.macro";

import Layout from "../components/Layout";
import PageWrapper from "../components/PageWrapper";
import ContentWrapper from "../components/ContentWrapper";
import SideWrapper from "../components/SideWrapper";
import InnerTitle from "../components/InnerTitle";
import SponsorSlider from "../components/SponsorSlider";
import PostLink from "../components/PostLink";
import Pagination from "../components/Pagination";
import Sidenavs from "../components/Sidenavs";

const Blog = ({ data, pageContext }) => {
  const seo = data.wpPage.seo;
  const { posts } = data.allWpPost;
  // Manually set the robots tag to never index paginated pages of blog.
  if (pageContext.pageNumber !== 0 || !!pageContext.category) {
    seo.metaRobotsNofollow = "nofollow";
    seo.metaRobotsNoindex = "noindex";
  } else {
    seo.metaRobotsNofollow = "follow";
    seo.metaRobotsNoindex = "index";
  }

  return (
    <Layout seo={data.wpPage.seo}>
      <PageWrapper>
        <ContentWrapper>
          <InnerTitle>
            {`Association Blog${
              pageContext.categoryName ? `: ${pageContext.categoryName}` : ``
            }`}
          </InnerTitle>
          <ul tw="flex flex-col gap-12 mt-8 max-w-[60rem]">
            {posts.map((post, i) => (
              <PostLink key={i} {...post} />
            ))}
          </ul>
          <Pagination
            tw="mt-12"
            category={pageContext.categoryUri}
            pageNumber={pageContext.humanPageNumber}
            numberOfPages={pageContext.numberOfPages}
          />
        </ContentWrapper>
        <SideWrapper>
          <Sidenavs showCategories />
          <SponsorSlider sidebar />
        </SideWrapper>
      </PageWrapper>
    </Layout>
  );
};

export const query = graphql`
  query IndexQuery(
    $limit: Int!
    $skip: Int!
    $category: String
    $ignore: String
  ) {
    wpPage(isPostsPage: { eq: true }) {
      ...SEO
    }
    allWpPost(
      sort: { fields: dateGmt, order: DESC }
      filter: {
        categories: {
          nodes: { elemMatch: { id: { eq: $category, ne: $ignore } } }
        }
      }
      limit: $limit
      skip: $skip
    ) {
      posts: nodes {
        ...PostList
      }
    }
  }
  fragment PostList on WpPost {
    title
    uri
    excerpt
    date(formatString: "MMMM DD, YYYY")
    featuredImage {
      node {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 90)
          }
        }
      }
    }
  }
`;

export default Blog;
