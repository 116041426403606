import React from "react";
import tw from "twin.macro";
import { Link as GLink } from "gatsby";

import Button from "./Button";

const Item = tw.li`flex flex-col items-start`;
const Link = tw(
  GLink
)`text-secondary font-din font-bold text-2xl duration-500 ease-in-out hover:text-secondaryLight`;
const Excerpt = tw.div``;

const PostLink = ({ uri, title, excerpt, ...rest }) => {
  return (
    <Item {...rest}>
      <Link to={uri}>{title}</Link>
      <Excerpt dangerouslySetInnerHTML={{ __html: excerpt }} />
      <Button tw="mt-6 text-base py-3 px-12" secondary url={uri}>
        Read More
      </Button>
    </Item>
  );
};

export default PostLink;
